import { browser as Bowser } from 'bowser'
Bowser.isTouch = function () {
  return Bowser.mobile || Bowser.tablet
}

require('../../src/less/bootstrap-3.less')
require('../../src/css/icomoon.css')
require('../../src/css/animate.css')
require('../../src/css/budicons.css')
require('../../src/css/opensans.css')
require('../../src/css/receipt.css')
require('../../src/less/app.less')
require('../../src/less/release-notes.less')
